// IMPORTS
@import (reference) "settings.less";

// NEWSLETTER
#newsletter {
    position: relative;
    margin-top: calc(-1 * var(--rowGap));

    &.unchecked .newsletter-clauses-wrapper:after {
        content: 'Zaznaczenie zgody jest konieczne, aby zapisać się do Newslettera';
        color: @error-color;
        margin-top: 10px;
    }
}

.newsletter-header {
    text-align: center;

    b {
        font-size: 28px;
        display: block;
    }

    p {
        margin-top: 4px;
    }
}

.newsletter-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 18px;
    width: 774px;

    input[type="text"] {
        width: 100%;
        height: 52px;
        background-color: transparent;
        border: 1px solid white;
        text-align: center;
        color: white;
        font-size: 16px;
        font-family: @main-font-family;

        &:focus::placeholder {
            color: transparent;
        }

        &::-webkit-input-placeholder {
            color: white;
        }
        &::-moz-placeholder {
            color: white;
        }
        &:-ms-input-placeholder {
            color: white;
        }
        &:-moz-placeholder {
            color: white;
        }
    }

    .popup-trigger {
        margin-top: 7px;
    }
}

.newsletter-clauses-wrapper.unchecked:after {
    content: 'Zaznaczenie zgody jest konieczne, aby zapisać się do Newslettera';
    clear: both;
    color: @error-color;
    margin-top: 10px;
}

// PRODUCERS BANNER
.producers-banner {
    padding: 0 87px;
    position: relative;

    .swiper-slide {
        width: 160px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: filter .2s ease-in-out;
    }

    img {
        max-width: 100%;
        width: auto;
        height: auto;
    }

    .swiper-button-prev {
        left: 0;
    }

    .swiper-button-next {
        right: 0;
    }
}

@media (min-width: 1280px) {
    .producers-banner {

        .swiper-slide {
            width: ~"calc((100% - (5 * 61px)) / 6)";
            margin-right: 61px;

            &:hover {
                filter: grayscale(1);
            }
        }
    }
}

// FOOTER
#footer {
    position: relative;
    background-color: @header-color;

    &:before {
        .full-bg();
    }
}

.footer-content {
    .grid();
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    padding: 24px 32px;
    gap: 46px 24px;
    align-items: start;

    a:not(.logo),
    span {
        color: @footer-gray;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.1em;
        transition: color 0.1s ease-in-out;
        padding: 8px 0;
        display: block;
    }

    @media(min-width: 1280px) {
        a:hover {
            color: @main-color;
        }
    }

    .footer-left { // only top left (cms)
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    .footer-right { // only top right (cms)
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        justify-content: flex-end;
        justify-self: end;
    }

    .sitemap {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .author {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        display: flex !important;
        justify-content: flex-end;
    }

    .logo {
        bottom: 50% !important;
        transform: translate(-50%, 50%) !important;
        height: 104px;
    }
}

.footer-left,
.footer-right {
    display: flex;
    gap: 24px;
    max-width: calc(100% - 80px);
    width: 100%;
    flex-wrap: wrap;
}

.footer-bottom-bar {
    display: contents;
}


@media (max-width: 1550px) and (min-width: 1280px) {
    .footer-bottom-bar {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
}